import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ConsultasService } from 'src/app/services/consultas.service';
import { Datos_Empresa } from 'src/app/interfaces/datos_empresa';

@Component({
  selector: 'app-info-empresa',
  templateUrl: './info-empresa.component.html',
  styleUrls: ['./info-empresa.component.css']
})
export class InfoEmpresaComponent implements OnInit {
  //variable que representa el formulario
  public validatingForm: FormGroup;
  public modificar: boolean;
  private datos_empresa: Datos_Empresa;

  constructor(private consultaService: ConsultasService) {
    this.modificar = false;
  }

  ngOnInit() {
    //Crea el obejto del formulario
    this.validatingForm = new FormGroup({
      email: new FormControl({ value: null, disabled: true }, [Validators.required, Validators.email, Validators.maxLength(50)]),
      direccion: new FormControl({ value: null, disabled: true }, [Validators.required]),
      telefono: new FormControl({ value: null, disabled: true }, [Validators.required]),
      nombreEmp: new FormControl({ value: null, disabled: true }, [Validators.required]),
      cuenta: new FormControl({ value: null, disabled: true }, [Validators.required]),
      banco: new FormControl({ value: null, disabled: true }, [Validators.required])
    });
    //Carga los datos de la empresa
    this.cargarInfoEmpresa();
  }

  //Metodo que carga la informacion de la empresa
  cargarInfoEmpresa() {
    this.consultaService.getDatosEmpresa().subscribe(respEmpresa => {
      if (respEmpresa != undefined && respEmpresa != null && respEmpresa.length > 0) {
        this.datos_empresa = respEmpresa[0];
        this.setFormulario();
      }
    }, error => {
      console.log("error al cargar la info de la empresa--> ", error);
    });
  }

  setFormulario() {
    this.validatingForm.get("email").setValue(this.datos_empresa.correo);
    this.validatingForm.get("direccion").setValue(this.datos_empresa.direccion);
    this.validatingForm.get("telefono").setValue(this.datos_empresa.telefono);
    this.validatingForm.get("nombreEmp").setValue(this.datos_empresa.nombre_emp);
    this.validatingForm.get("cuenta").setValue(this.datos_empresa.num_cuentaBanc);
    this.validatingForm.get("banco").setValue(this.datos_empresa.nombre_banco);
  }

  btnModificarCampos() {
    this.modificar = true;
    this.validatingForm.get("email").enable();
    this.validatingForm.get("direccion").enable();
    this.validatingForm.get("telefono").enable();
    this.validatingForm.get("nombreEmp").enable();
    this.validatingForm.get("cuenta").enable();
    this.validatingForm.get("banco").enable();
  }

  btnActualizar(value) {
    this.datos_empresa.correo = value.email;
    this.datos_empresa.direccion = value.direccion;
    this.datos_empresa.telefono = value.telefono;
    this.datos_empresa.nombre_emp = value.nombreEmp;
    this.datos_empresa.num_cuentaBanc = value.cuenta;
    this.datos_empresa.nombre_banco = value.banco;

    this.consultaService.setDatosEmpresa(this.datos_empresa).then(() => {
      this.modificar = false;
      this.validatingForm.get("email").disable();
      this.validatingForm.get("direccion").disable();
      this.validatingForm.get("telefono").disable();
      this.validatingForm.get("nombreEmp").disable();
      this.validatingForm.get("cuenta").disable();
      this.validatingForm.get("banco").disable();
    }).catch(err => { console.log(err) });
  }

  btnCancelar() {
    this.modificar = false;
    this.setFormulario();
    this.validatingForm.get("email").disable();
    this.validatingForm.get("direccion").disable();
    this.validatingForm.get("telefono").disable();
    this.validatingForm.get("nombreEmp").disable();
    this.validatingForm.get("cuenta").disable();
    this.validatingForm.get("banco").disable();
  }

}
