import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable()
export class LoginService {


  constructor(private auth: AngularFireAuth) { }

  //Devuelve la sesion
  get Session() {
    return this.auth.authState;
  }

  //Login de usuario
  loginUser(email: string, password: string) {
    return this.auth.auth.signInWithEmailAndPassword(email, password);
  }

  // Logout de usuario
  logout() {
    return this.auth.auth.signOut();
  }

  //Envia codigo de recuperacion de password al email
  resetPass(email: string) {
    return this.auth.auth.sendPasswordResetEmail(email);
  }

  

}
