import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ServiciosComponent } from './servicios/servicios.component';
import { DetalleServicioComponent } from './detalle-servicio/detalle-servicio.component';
import { PersonalComponent } from './personal/personal.component';
import { InfoEmpresaComponent } from './info-empresa/info-empresa.component';

const routes: Routes = [
  {
    path: 'dashboard',
    component: DashboardComponent,
    //canActivate: [AngularFireAuthGuard], 
    //data: { authGuardPipe: redirectLoggedInToAdmin }
    //canActivate: [redirectLoggedInToAdmin]
    children: [
      {
        path: 'servicios',
        component: ServiciosComponent
      },
      {
        path: 'detalleServicios',
        component: DetalleServicioComponent
      },
      {
        path: 'personal',
        component: PersonalComponent
      },
      {
        path: 'infoEmpresa',
        component: InfoEmpresaComponent
      }
    ]
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PrivateRoutingModule { }
