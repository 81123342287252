import { Component, OnInit, ViewChild } from '@angular/core';
import { Servicio } from 'src/app/interfaces/servicio';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { ConsultasService } from 'src/app/services/consultas.service';
import { Location } from '@angular/common';

export interface ServiceData {
  consecutivo: number,
  fecha: string,
  tipoServicio: string,
  cliente: string,
  valor: string,
  propina: number,
  peaje: number,
  valorTotal: number
}

@Component({
  selector: 'app-detalle-servicio',
  templateUrl: './detalle-servicio.component.html',
  styleUrls: ['./detalle-servicio.component.css']
})
export class DetalleServicioComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  /********************************
  * ATRIBUTOS DEL ELEMENTO TABLA
  *******************************/
  public displayedColumns: string[] = ['consecutivo', 'fecha', 'tipoServicio', 'cliente', 'valorServicio', 'peaje', 'propina', 'valor'];
  public dataSource: MatTableDataSource<ServiceData>;

  public servicios: Array<ServiceData>;
  public conductor: string;
  public cargarTodo: boolean;
  private TRANSPORTE: string = "Transporte";
  private startDate: Date;
  private endDate: Date;

  constructor(private location: Location, private route: ActivatedRoute, private consultaService: ConsultasService) {
    this.route.params.subscribe(params => {
      this.conductor = params.nombre;
      this.cargarTodo = params.getTodos == '1' ? true : false;
      this.startDate = new Date(params.startDate);
      this.endDate = new Date(params.endDate);
      this.getTodosServicios(params.conductor);
    }, error => {
      console.log("Error--> ", error);
    });
  }

  ngOnInit() {
  }

  //Metodo que obtiene todos los servicios
  getTodosServicios(idConductor: string) {
    this.servicios = [];
    let subscribe1 = this.consultaService.getServicios_Conductor(idConductor).subscribe(respServicios => {
      if (respServicios != undefined && respServicios != null && respServicios.length > 0) {
        let consecutivo: number = 0;
        respServicios.forEach(element => {
          let subscribe2 = this.consultaService.getCliente_X_Id(element.cliente_id).subscribe(respCliente => {
            ++consecutivo;
            let tipoServicio: string = element.tipo_servicio;
            if (element.tipo_servicio != this.TRANSPORTE) {
              tipoServicio += " (" + element.horas_servicio + " horas)";
            }
            let totalPeaje: number = 0;
            //Obtiene el total de los peajes
            element.peaje.forEach(objPeaje => {
              totalPeaje += parseFloat(""+objPeaje.valor);
            });
            let valorTotal: number = parseFloat(element.valor_servicio.split('USD$')[1]) + parseFloat(""+element.propina);
            valorTotal = Math.round(valorTotal * 100) / 100;
            let objServi: ServiceData = {
              consecutivo: consecutivo,
              fecha: element.fecha_programada != "" ? element.fecha_programada : element.fecha_solicitud,
              tipoServicio: tipoServicio,
              cliente: respCliente.nombre_completo,
              valor: "USD$"+ (Math.round((parseFloat(element.valor_servicio.split('USD$')[1]) - totalPeaje) * 100)/100),//element.valor_servicio,
              propina: element.propina,
              peaje: totalPeaje,
              valorTotal: valorTotal
            };
            this.servicios.push(objServi);
            if (this.cargarTodo) {
              //Anexa el array en la tabla
              this.dataSource = new MatTableDataSource(this.servicios);
              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;
            } else {
              this.getServicios_rangoFecha(this.startDate, this.endDate);
            }

            subscribe2.unsubscribe();
          }, error => {
            console.log("error--> ", error);
          });
        });
        subscribe1.unsubscribe();
      }
    }, error => {
      console.log("error--> ", error);
    });
  }

  //Metodo que obtiene los servicios por rango de fecha
  getServicios_rangoFecha(startDate: Date, endDate: Date) {
    let arrayServicios: Array<ServiceData> = [];
    this.servicios.forEach(element => {
      let mes: number;
      let fechaServicio = element.fecha.split("/");
      //crea objeto con la fecha del servicio
      mes = parseInt(fechaServicio[1]) - 1;
      let dateServicio: Date = new Date(parseInt(fechaServicio[2]), mes, parseInt(fechaServicio[0]));
      if (startDate <= dateServicio && endDate >= dateServicio) {
        arrayServicios.push(element);
      }
    });
    //this.servicios = arrayServicios;
    this.dataSource = new MatTableDataSource(arrayServicios);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  //Manejador de evento del boton 'atras'
  btnAtras() {
    this.location.back();
  }
}
