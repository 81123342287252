import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { ConsultasService } from 'src/app/services/consultas.service';
import { saveAs } from 'file-saver';
import * as JSZip from 'jszip';

export interface PersonalData {
  consecutivo: number,
  idConductor: string,
  nombre: string,
  apellido: string,
  celular: string,
  email: string,
  fechaNaci: string,
  edad: number,
  placaVehiculo: string,
  documentos: Array<ImagenesData>,
  descargandoDocs: boolean
}

export interface ImagenesData {
  url: string,
  nombreImg: string
}

@Component({
  selector: 'app-personal',
  templateUrl: './personal.component.html',
  styleUrls: ['./personal.component.css']
})
export class PersonalComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  /********************************
  * ATRIBUTOS DEL ELEMENTO TABLA
  *******************************/
  public displayedColumns: string[] = ['consecutivo', 'nombre', 'apellido', 'celular', 'email',
    'fechaNaci', 'edad', 'placaVehiculo', 'documentos', 'darAlta'];
  public dataSource: MatTableDataSource<PersonalData>;
  public conductores: Array<PersonalData>;
  public verMensaje: boolean;
  public disabledDarAlta: boolean;
  public verTodo: boolean;

  constructor(private consultaService: ConsultasService) {
    this.conductores = [];
    this.verMensaje = false;
    this.disabledDarAlta = true;
    this.verTodo = true;
    this.getConductores_Validados();
  }

  ngOnInit() {
  }

  //funcion para aplicar filtro en la tabla
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  /***********************
   * METODOS QUE MANEDA 
   * LOS RADIO BUTTONS
   ***********************/
  btnVerTodo() {
    this.verTodo = true;
    this.disabledDarAlta = true;
    this.getConductores_Validados();
  }
  btnVerNuevos() {
    this.verTodo = false;
    this.disabledDarAlta = false;
    this.getConductores_NoValidados();
  }

  /*************************************************
   * METODO QUE OBTIENE LOS CONDUCTORES QUE -->> NO <<--
   * ESTÁN VALIDADOS
   *************************************************/
  getConductores_NoValidados() {
    let subscription = this.consultaService.getConductores_NoValidados().subscribe(respConductores => {
      this.conductores = [];
      if (respConductores != undefined && respConductores != null && respConductores.length > 0) {
        let consecutivo: number = 0;
        respConductores.forEach(element => {
          let date: Date = new Date();
          let arrayFechaBD = element.fecha_naci.split("/");
          let edad: number = date.getFullYear() - parseInt(arrayFechaBD[2]);
          //Almacena la url de los documentos 
          let arrayString: Array<ImagenesData> = [];
          arrayString.push({ url: element.urlFotoCarroDer, nombreImg: "FotoCarroDer" });
          arrayString.push({ url: element.urlFotoCarroFrente, nombreImg: "FotoCarroFrente" });
          arrayString.push({ url: element.urlFotoCarroIzq, nombreImg: "FotoCarroIzq" });
          arrayString.push({ url: element.urlFotoPersona, nombreImg: "FotoPersona" });
          arrayString.push({ url: element.urlLicencia, nombreImg: "Licencia" });
          arrayString.push({ url: element.urlSeguro, nombreImg: "Seguro" });
          arrayString.push({ url: element.urlSocialSecurity, nombreImg: "SocialSecurity" });
          //crea el objeto
          ++consecutivo;
          let tempoPersonal: PersonalData = {
            consecutivo: consecutivo,
            idConductor: element.id,
            nombre: element.nombre,
            apellido: element.apellido,
            celular: element.celular,
            email: element.email,
            fechaNaci: element.fecha_naci,
            edad: edad,
            placaVehiculo: element.placa_vehiculo,
            documentos: arrayString,
            descargandoDocs: false
          }
          this.conductores.push(tempoPersonal);
        });
        this.dataSource = new MatTableDataSource(this.conductores);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      } else {
        this.dataSource = new MatTableDataSource(this.conductores);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.verMensaje = true;
      }
      subscription.unsubscribe();
    }, error => {
      console.log("error-> ", error);
      subscription.unsubscribe();
    });
  }

  /************************************************
   * METODO QUE OBTIENE TODOS LOS CONDUCTORES
   * QUE ESTÁN VALIDADOS
   ***********************************************/
  getConductores_Validados() {
    let subscription = this.consultaService.getConductores().subscribe(respConductores => {
      this.conductores = [];
      if (respConductores != undefined && respConductores != null && respConductores.length > 0) {
        let consecutivo: number = 0;
        respConductores.forEach(element => {
          let date: Date = new Date();
          let arrayFechaBD = element.fecha_naci.split("/");
          let edad: number = date.getFullYear() - parseInt(arrayFechaBD[2]);
          //Almacena la url de los documentos 
          let arrayString: Array<ImagenesData> = [];
          arrayString.push({ url: element.urlFotoCarroDer, nombreImg: "FotoCarroDer" });
          arrayString.push({ url: element.urlFotoCarroFrente, nombreImg: "FotoCarroFrente" });
          arrayString.push({ url: element.urlFotoCarroIzq, nombreImg: "FotoCarroIzq" });
          arrayString.push({ url: element.urlFotoPersona, nombreImg: "FotoPersona" });
          arrayString.push({ url: element.urlLicencia, nombreImg: "Licencia" });
          arrayString.push({ url: element.urlSeguro, nombreImg: "Seguro" });
          arrayString.push({ url: element.urlSocialSecurity, nombreImg: "SocialSecurity" });
          //crea el objeto
          ++consecutivo;
          let tempoPersonal: PersonalData = {
            consecutivo: consecutivo,
            idConductor: element.id,
            nombre: element.nombre,
            apellido: element.apellido,
            celular: element.celular,
            email: element.email,
            fechaNaci: element.fecha_naci,
            edad: edad,
            placaVehiculo: element.placa_vehiculo,
            documentos: arrayString,
            descargandoDocs: false
          }
          this.conductores.push(tempoPersonal);
        });
        this.dataSource = new MatTableDataSource(this.conductores);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      } else {
        this.dataSource = new MatTableDataSource(this.conductores);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.verMensaje = true;
      }
      subscription.unsubscribe();
    }, error => {
      console.log("error-> ", error);
      subscription.unsubscribe();
    });
  }

  //Manejador de evento del boton download
  btnDownload(arrayRutaDocs: Array<ImagenesData>, nombConductor: string, pos: number) {
    let zip: JSZip = new JSZip();
    let cont: number = 0;
    this.conductores[pos].descargandoDocs = true;
    arrayRutaDocs.forEach(element => {
      let subs1 = this.consultaService.getDocs(element.url).subscribe((data) => {
        let subs2 = this.consultaService.getImage(data).subscribe(resp => {
          let type = resp.type.split("/");
          zip.file(element.nombreImg + "." + type[1], resp);
          ++cont;
          if (arrayRutaDocs.length == cont) {
            zip.generateAsync({ type: "blob" })
              .then(function (content) {
                // see FileSaver.js
                saveAs(content, nombConductor + ".zip");
              }
              );
            this.conductores[pos].descargandoDocs = false;
          }
          subs2.unsubscribe();
        }, err => console.log("err--> ", err));
        subs1.unsubscribe();
      }, error => console.log(error));
    });
  }

  //Metodo manejador de evento del boton dar de alta
  btnDarAlta(idConductor: string) {
    this.consultaService.setValidar_conductor(idConductor, true, true).then(() => {
      console.log("conductor validado");
      this.getConductores_NoValidados();
    }).catch(error => {
      console.log("error al validar el conductor--> ", error);
    });
  }

  btnDarBaja(idConductor: string) {
    this.consultaService.setValidar_conductor(idConductor, false, false).then(() => {
      console.log("conductor validado");
      this.getConductores_Validados();
    }).catch(error => {
      console.log("error al validar el conductor--> ", error);
    });
  }



}
