import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { DialogPasswordComponent } from '../dialog-password/dialog-password.component';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  /**************
   * ATRIBUTOS
   ***************/
  public opciones: Array<string>;

  constructor(private route: Router, public dialog: MatDialog, private loginService: LoginService) {
    this.opciones = [];
    this.inicializarOpciones();
    //this.navegar(this.opciones[0]);
    //Mostrar mensaje en consola
    console.log("%c¡DETENTE!", "color:red;font-size: 3.5rem;font-weight: bold;");
    console.log("%cTODO LO QUE HAGAS AQUÍ DEJARÁ RASTRO EN NUESTRO SISTEMA. CIERRA ESTA CONSOLA Y EVITA PROBLEMAS LEGALES.", "color:red;font-size: 16px;font-weight: bold;");
    //Fin mensaje de consola
  }

  ngOnInit() {
  }

  inicializarOpciones() {
    this.opciones.push("Servicios");
    this.opciones.push("Personal");
    this.opciones.push("Empresa");
  }

  navegar(opc: string) {
    /******************************************************
     * redirecciona a la opcion escogida por el usuario
     *****************************************************/
    if (opc == this.opciones[0]) {
      this.route.navigate(['/dashboard/servicios']);
    } else if (opc == this.opciones[1]) {
      this.route.navigate(['/dashboard/personal']);
    } else if (opc == this.opciones[2]) {
      this.route.navigate(['/dashboard/infoEmpresa']);
    }
  }
  cerrarSesion() {
    localStorage.clear();
    this.loginService.logout();
    this.route.navigate(['/login']);
  }

  openModal() {
    let dialogRef = this.dialog.open(DialogPasswordComponent, {
      width: '300px'
    });

    dialogRef.afterClosed().subscribe(result => {
    }, error => { console.log(error) });
  }

}


