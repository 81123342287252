import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { ConsultasService } from 'src/app/services/consultas.service';
import { Servicio } from 'src/app/interfaces/servicio';
import { Router } from '@angular/router';

export interface ServiceData {
  consecutivo: number,
  idConductor: string,
  conductor: string,
  cantServicios: number,
  valServicios: number,
  porcentaje: number,
  totalPagar: number,
  habilitado: boolean,
  servicios: Servicio[],
  propinas: number,
  peajes: number
}

@Component({
  selector: 'app-servicios',
  templateUrl: './servicios.component.html',
  styleUrls: ['./servicios.component.css']
})
export class ServiciosComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  /********************************
  * ATRIBUTOS DEL ELEMENTO TABLA
  *******************************/
  public displayedColumns: string[] = ['consecutivo', 'conductor', 'cantServicios', 'valServicios', 'porcentaje', 'peajes', 'propinas', 'totalPagar', 'habilitado'];
  public dataSource: MatTableDataSource<ServiceData>;
  public startDate: Date;
  public endDate: Date;
  public verTodo: boolean;
  public showMessage: boolean;

  public servicios: Array<ServiceData>;
  private valor_porcentaje: number = 0.25;//25%
  constructor(private consultaService: ConsultasService, private route: Router) {
    this.inicializarVariables();
  }

  ngOnInit() {
    this.getTotalServicios();
  }

  //Metodo que inicializa las variables
  inicializarVariables() {
    this.showMessage = false;
    this.servicios = [];
    this.startDate = new Date();
    this.endDate = new Date();
    this.verTodo = true;
  }

  //Metodo manejador de eventos del boton 'refrescar'
  btnRefrescar() {
    this.inicializarVariables();
    this.getTotalServicios();
  }

  //funcion para aplicar filtro en la tabla
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  radioTodo() {
    this.verTodo = true;
    this.dataSource = new MatTableDataSource(this.servicios);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  radioFecha() {
    this.verTodo = false;
  }

  //METODO QUE OBTIENE EL TOTAL DE LOS SERVICIOS CON SUS RESPECTIVOS CONDUCTORES
  getTotalServicios() {
    let subscribe = this.consultaService.getConductores().subscribe(respConductores => {
      if (respConductores != undefined && respConductores != null && respConductores.length > 0) {
        let contador: number = 0;
        let cont2: number = 0;
        respConductores.forEach(conductor => {
          ++cont2;
          let nombre: string = conductor.nombre + ' ' + conductor.apellido;
          let subscribe2 = this.consultaService.getServicios_Conductor(conductor.id).subscribe(respServicios => {
            console.log("servicios---> ", respServicios);
            if (respServicios != undefined && respServicios != null && respServicios.length > 0) {
              let cantServi: number = respServicios.length;
              let valTotal_servicios: number = 0;
              let valTotal_propinas: number = 0;
              let valTotal_peajes: number = 0;
              respServicios.forEach(servicio => {
                let val_servicio = servicio.valor_servicio.split('$');
                valTotal_servicios += parseFloat(val_servicio[1]);
                valTotal_propinas += parseFloat(""+servicio.propina);
                //suma los peajes
                servicio.peaje.forEach(objPeaje => {
                  valTotal_peajes += parseFloat(""+objPeaje.valor);
                });
              });
              let valServicio = valTotal_servicios - valTotal_peajes;
              let porcentaje: number = Math.round((valServicio * this.valor_porcentaje) * 100) / 100;//valTotal_servicios
              let totalPagar: number = Math.round((valTotal_servicios + valTotal_propinas - porcentaje) * 100) / 100;
              ++contador;
              //inserta los datos en el array
              let objServicio: ServiceData = {
                consecutivo: contador,
                idConductor: conductor.id,
                conductor: nombre,
                cantServicios: cantServi,
                valServicios: (Math.round(valTotal_servicios * 100) / 100),
                porcentaje: porcentaje,
                totalPagar: totalPagar,
                habilitado: conductor.habilitado,
                servicios: respServicios,
                propinas: valTotal_propinas,
                peajes: valTotal_peajes
              };
              this.servicios.push(objServicio);
              this.dataSource = new MatTableDataSource(this.servicios);
              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;
            }

            if (respConductores.length == cont2) {
              if (this.servicios.length == 0) {
                this.showMessage = true;
              } else {
                this.showMessage = false;
              }
            }
            subscribe2.unsubscribe();
          });
        });

      }
      subscribe.unsubscribe();
    }, error => {
      console.log("error--> ", error);
    });
  }

  //Habilita o deshabilita a un conductor
  btnToggle(idConductor: string, habilitado: boolean) {
    this.consultaService.setConductor_Habilitado(idConductor, habilitado).then(() => {
    }).catch(error => {
      console.log("error--> ", error);
    });
  }

  //Buscar por rango de fecha
  btnBuscar() {
    let arrayServicios: Array<ServiceData> = [];
    let rowServicio: ServiceData;
    this.servicios.forEach(servicio => {
      rowServicio = {
        consecutivo: servicio.consecutivo,
        idConductor: servicio.idConductor,
        conductor: servicio.conductor,
        cantServicios: servicio.cantServicios,
        valServicios: servicio.valServicios,
        porcentaje: servicio.porcentaje,
        totalPagar: servicio.totalPagar,
        habilitado: servicio.habilitado,
        servicios: servicio.servicios,
        propinas: servicio.propinas,
        peajes: servicio.peajes
      };
      console.log("------");
      let objServicios: Servicio[] = rowServicio.servicios;
      let serviciosTempo: Servicio[] = [];
      let cantServi: number = 0;
      let valServicios: number = 0;
      objServicios.forEach(element => {
        let mes: number;
        let cadenaSolicitud = element.fecha_solicitud.split("/");
        let cadenaProgramada = element.fecha_programada.split("/");
        //crea objeto con la fecha de la solicitud de un servicio
        mes = parseInt(cadenaSolicitud[1]) - 1;
        let dateSolicitud: Date = new Date(parseInt(cadenaSolicitud[2]), mes, parseInt(cadenaSolicitud[0]));
        //crea objeto con la fecha programada para un servicio
        mes = parseInt(cadenaProgramada[1]) - 1;
        let dateProgramada: Date = new Date(parseInt(cadenaProgramada[2]), mes, parseInt(cadenaProgramada[0]));
        //Almacena todos los servicios que estan dentro del rango seleccionado por el usuario
        console.log("solicitud--> ", dateSolicitud.getMonth());
        if (
          (this.startDate <= dateSolicitud || this.startDate <= dateProgramada)
          && (this.endDate >= dateSolicitud || this.endDate >= dateProgramada)) {
          ++cantServi;
          let val_servicio = element.valor_servicio.split('$');
          valServicios += parseFloat(val_servicio[1]);
          serviciosTempo.push(element);
          console.log("-----> ", element);
        }
      });
      if (serviciosTempo.length > 0) {
        let porcentaje: number = Math.round((valServicios * this.valor_porcentaje) * 100) / 100;
        let totalPagar: number = Math.round((valServicios - porcentaje) * 100) / 100;
        rowServicio.cantServicios = cantServi;
        rowServicio.valServicios = (Math.round(valServicios * 100) / 100);
        rowServicio.porcentaje = porcentaje;
        rowServicio.totalPagar = totalPagar;
        rowServicio.servicios = serviciosTempo;
        arrayServicios.push(rowServicio);
      }
    });
    this.dataSource = new MatTableDataSource(arrayServicios);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  //METODO QUE REDIRIGE A DETALLES SERVICIO
  goDetalleServicio(rowService: ServiceData) {
    let params: any;
    if (this.verTodo) {
      params = {
        getTodos: this.verTodo ? 1 : 0,
        conductor: rowService.idConductor,
        nombre: rowService.conductor
      };
    } else {
      params = {
        getTodos: this.verTodo ? 1 : 0,
        conductor: rowService.idConductor,
        nombre: rowService.conductor,
        startDate: this.startDate,
        endDate: this.endDate
      }
    }

    this.route.navigate(['/dashboard/detalleServicios', params]);
  }

}
