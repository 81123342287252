export const FIREBASE_CONFIG = {
    apiKey: "AIzaSyATJQWtUfEw-YDe8xiGipXcrVg6feoPzic",
    authDomain: "nexttooyou-8ee52.firebaseapp.com",
    databaseURL: "https://nexttooyou-8ee52.firebaseio.com",
    projectId: "nexttooyou-8ee52",
    storageBucket: "nexttooyou-8ee52.appspot.com",
    messagingSenderId: "1072486905229",
    appId: "1:1072486905229:web:f22d309b14cc7fd46bb9c4",
    measurementId: "G-TM069N46X3"

};

//BLOQUE CON LA LLAVE PARA AMBIENTE DE DESARROLLO
/*apiKey: "AIzaSyB2d2uGjvOLvRQYRhDT_wqmhM8uD2dfoyo",
    authDomain: "nexttoyou-7af07.firebaseapp.com",
    databaseURL: "https://nexttoyou-7af07.firebaseio.com",
    projectId: "nexttoyou-7af07",
    storageBucket: "nexttoyou-7af07.appspot.com",
    messagingSenderId: "32999433251",
    appId: "1:32999433251:web:afd809e800c78941"
    */

