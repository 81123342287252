import { Injectable } from '@angular/core';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Servicio } from '../interfaces/servicio';
import { Cliente } from '../interfaces/cliente';
import { Datos_Empresa } from '../interfaces/datos_empresa';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Conductor } from '../interfaces/conductor';
import { Administrador } from '../interfaces/administradores';

@Injectable()
export class ConsultasService {

  /***************
   * ATRIBUTOS
   **************/
  private COLLECT_SERVICIOS: string = 'Servicios';
  private COLLECT_CLIENTES: string = 'Clientes';
  private COLLECT_EMPRESA: string = 'Datos_Empresa';
  private COLLECT_CONDUCTORES: string = 'Conductores';
  private COLLECT_ADMINISTRADORES: string = 'Administradores';

  private serviciosCollection: AngularFirestoreCollection<Servicio>;
  private clientesCollection: AngularFirestoreCollection<Cliente>;
  private conductoresCollection: AngularFirestoreCollection<Conductor>;
  private datosEmpresaCollection: AngularFirestoreCollection<Datos_Empresa>;
  private administradoresCollection: AngularFirestoreCollection<Administrador>;

  constructor(private db: AngularFirestore, private storage: AngularFireStorage, public http: HttpClient) { }

  //METODO PARA OBTENER TODOS LOS SERVICIOS
  getServicios_Conductor(idConductor: string) {
    //Obtiene todos los servicios realizados
    this.serviciosCollection = this.db.collection<Servicio>(this.COLLECT_SERVICIOS,
      ref => ref.where('conductor_id', '==', idConductor)
      .where('cancelado', '==', false));

    //Retorna el resultado
    return this.serviciosCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
  }


  //METODO PARA OBTENER LA INFORMACION DE UN CONDUCTOR
  getConductor_X_Id(idConductor: string) {
    let conductoresCollection = this.db.doc<Conductor>(this.COLLECT_CONDUCTORES + "/" + idConductor);

    //Retorna el resultado
    return conductoresCollection.valueChanges().pipe(
      map(actions => {
        console.log("actions--> ", actions);
        return actions;
      })
    );
  }

  getConductores() {
    //Obtiene todos los conductores dados de alta
    this.conductoresCollection = this.db.collection<Conductor>(this.COLLECT_CONDUCTORES,
      ref => ref.where('validado', '==', true));

    //Retorna el resultado
    return this.conductoresCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
  }

  //METODO PARA OBTENER LA INFORMACION DE UN CLIENTE
  getCliente_X_Id(idCliente: string) {
    let clientesCollection = this.db.doc<Cliente>(this.COLLECT_CLIENTES + "/" + idCliente);

    //Retorna el resultado
    return clientesCollection.valueChanges().pipe(
      map(actions => {
        return actions;
      })
    );
  }

  //METODO QUE HABILITA O DESHABILITA A UN CONDUCTOR
  setConductor_Habilitado(idConductor: string, habilitado: boolean) {
    return this.db.doc(this.COLLECT_CONDUCTORES + "/" + idConductor).update({ habilitado: habilitado });
  }

  //Metodo que obtiene a los conductores que aún no están validados
  getConductores_NoValidados() {
    //Obtiene todos los conductores dados de alta
    this.conductoresCollection = this.db.collection<Conductor>(this.COLLECT_CONDUCTORES,
      ref => ref.where('validado', '==', false));

    //Retorna el resultado
    return this.conductoresCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
  }

  //Metodo que valida un conductor en la plataforma
  setValidar_conductor(idConductor: string, validado: boolean, habilitado: boolean) {
    return this.db.doc(this.COLLECT_CONDUCTORES + "/" + idConductor).update({ validado: validado, habilitado: habilitado });
  }

  //METODO PARA OBTENER ARCHIVOS DEL STORAGE
  getDocs(urlDocs: string): Observable<string | null> {
    return this.storage.ref(urlDocs).getDownloadURL();
  }

  //METODO PARA OBTENER UNA IMAGEN A PARTIR DE UNA URL
  getImage(imageUrl: string) {
    return this.http.get('https://cors-anywhere.herokuapp.com/'+imageUrl, { headers: {'Access-Control-Allow-Origin': '*'}, observe: 'response', responseType: 'blob' }).pipe(
      map((res) => {
        return new Blob([res.body], { type: res.headers.get('Content-Type') });
      })
    );

  }

  //METODO PARA OBTENER LOS DATOS DE LA EMPRESA
  getDatosEmpresa(): Observable<Datos_Empresa[]> {
    //Obtiene los conductores disponibles
    this.datosEmpresaCollection = this.db.collection<Datos_Empresa>(this.COLLECT_EMPRESA);

    //Retorna el resultado
    return this.datosEmpresaCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
  }

  setDatosEmpresa(datos_empresa: Datos_Empresa) {
    let objEmp: Datos_Empresa = {
      correo: datos_empresa.correo,
      direccion: datos_empresa.direccion,
      nombre_banco: datos_empresa.nombre_banco,
      nombre_emp: datos_empresa.nombre_emp,
      num_cuentaBanc: datos_empresa.num_cuentaBanc,
      telefono: datos_empresa.telefono
    }
    return this.db.doc(this.COLLECT_EMPRESA + "/" + datos_empresa.id).update(objEmp);
  }

  //Metodo que obtiene todos los admin
  getAdministrador(email: string) {
    //Obtiene todos los administradores
    this.administradoresCollection = this.db.collection<Administrador>(this.COLLECT_ADMINISTRADORES,
      ref => ref.where('email', '==', email));

    //Retorna el resultado
    return this.administradoresCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
  }


}
