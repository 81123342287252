import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-dialog-password',
  templateUrl: './dialog-password.component.html',
  styleUrls: ['./dialog-password.component.css']
})
export class DialogPasswordComponent implements OnInit {

  constructor(private loginService: LoginService, public dialogRef: MatDialogRef<DialogPasswordComponent>) { }

  ngOnInit() { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  btnConfirmar() {
    let email: string = localStorage.getItem("email");
    if (email != undefined && email != null && email != "") {
      this.loginService.resetPass(email).then(() => { }).catch(error => {
        console.log(error);
      });
    }
    this.dialogRef.close();
  }

}
