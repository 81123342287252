import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';
import { ConsultasService } from 'src/app/services/consultas.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  /***************************
   * CODIGOS DE ERROR
   * DE FIREBASE
   **************************/
  public USER_NOT_FOUND = "auth/user-not-found";
  public WRONG_PASS = "auth/wrong-password";

  /**************
   * ATRIBUTOS
   **************/
  //variable que representa el formulario
  public validatingForm: FormGroup;
  //variable que representa el alerta
  public alerta: boolean;
  public mensaje: string;
  public procesando: boolean;

  constructor(private route: Router, private loginService: LoginService,
    private consultaService: ConsultasService) {
    this.alerta = false;
    this.procesando = false;
    this.mensaje = "";
    //Mostrar mensaje en consola
    console.log("%c¡DETENTE!", "color:red;font-size: 3.5rem;font-weight: bold;");
    console.log("%cTODO LO QUE HAGAS AQUÍ DEJARÁ RASTRO EN NUESTRO SISTEMA. CIERRA ESTA CONSOLA Y EVITA PROBLEMAS LEGALES.", "color:red;font-size: 16px;font-weight: bold;");
    //Fin mensaje de consola
  }


  async ngOnInit() {
    this.validatingForm = new FormGroup({
      usuario: new FormControl(null, [Validators.required, Validators.email, Validators.maxLength(50)]),
      password: new FormControl(null, [Validators.required])
    });

  }

  /*************************************************
   * 
   * Metodo validador de los inputs del formulario
   * 
   *************************************************/
  get inputUsuario() {
    return this.validatingForm.get('usuario');
  }

  get inputPass() {
    return this.validatingForm.get('password');
  }

  /*******************************************
   * 
   * Manejador de eventos del boton 'ENTRAR'
   *
   *******************************************/
  //recibe como parametro un objeto de tipo value del formGroup
  login(value) {
    this.alerta = false;
    let email: string = value.usuario;
    let pass: string = value.password;
    this.procesando = true;
    this.loginService.loginUser(email, pass).then(resp => {
      localStorage.setItem("email", email);
      //Obtiene el id del cliente para actualizar el token en caso de haber cambiado de dispositivo
      let subscription = this.consultaService.getAdministrador(email).subscribe(respAdmin => {
        console.log("respAdmin--> ", respAdmin);
        if (respAdmin != undefined && respAdmin != null && respAdmin.length > 0) {
          this.alerta = false;
          localStorage.setItem("email", email);
          this.route.navigate(['/dashboard/servicios']);
        } else {
          this.loginService.logout();
          this.mensaje = "El usuario no existe";
          this.alerta = true;
        }
        subscription.unsubscribe();
        this.procesando = false;
      }, error => {
        this.procesando = false;
        console.log(error);
      });
      //Usuario logueado
    }).catch(err => {
      this.procesando = false;
      console.log("error--> ", err);
      if (err.code == this.USER_NOT_FOUND) {
        this.mensaje = "El usuario no existe";
        this.alerta = true;
      } else if (err.code == this.WRONG_PASS) {
        this.mensaje = "Credenciales incorrectas";
        this.alerta = true;
      }
    });
  }

}
