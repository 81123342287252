import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ServiciosComponent } from './servicios/servicios.component';
import { DetalleServicioComponent } from './detalle-servicio/detalle-servicio.component';
import { DashboardComponent } from './dashboard/dashboard.component';
//Importaciones de angular-material
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatChipsModule } from '@angular/material/chips';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatBadgeModule } from '@angular/material/badge';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PrivateRoutingModule } from './private-routing.module';
import { ConsultasService } from '../services/consultas.service';
import { PersonalComponent } from './personal/personal.component';
import { HttpClientModule } from '@angular/common/http';
import { InfoEmpresaComponent } from './info-empresa/info-empresa.component';
import { DialogPasswordComponent } from './dialog-password/dialog-password.component'

@NgModule({
  declarations: [
    ServiciosComponent,
    DetalleServicioComponent,
    DashboardComponent,
    PersonalComponent,
    InfoEmpresaComponent,
    DialogPasswordComponent
  ],
  entryComponents:[
    ServiciosComponent,
    DetalleServicioComponent,
    DashboardComponent,
    PersonalComponent,
    InfoEmpresaComponent,
    DialogPasswordComponent
  ],
  imports: [
    CommonModule,
    PrivateRoutingModule,
    HttpClientModule,
    MatToolbarModule,
    MatMenuModule,
    BrowserAnimationsModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatToolbarModule,
    MatCardModule,
    MatInputModule,
    MatButtonModule,
    MatListModule,
    MatIconModule,
    MatSidenavModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    MatSnackBarModule,
    MatCheckboxModule,
    MatStepperModule,
    MatProgressBarModule,
    MatRadioModule,
    MatButtonToggleModule,
    MatBadgeModule,
    MatDatepickerModule,
    MatNativeDateModule
  ],
  providers: [
    ConsultasService
  ]
})
export class PrivateModule { }
